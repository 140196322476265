import request from "../vendors/request";
import AbstractComponent from "./abstract-component";
import { gsap } from 'gsap';
import Cursor from "./cursor";

export default class CustomerCaseList extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.request = null;
        this.class = "c-customer-cases-list";
        this.action = "filter_customer_cases_ajax";
        this.params = {
            sector: [],
            job: [],
            page: 1
        };
        this.lastFetch = null;
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM.column = this.$el.querySelector('.' + this.class + '__list');
        this.DOM.noPostMsg = this.$el.querySelector('.no-post');
        this.DOM.moreBtn = this.$el.querySelector('.moreBtn');
        this.DOM.checkboxes = this.$el.querySelectorAll('input[type="checkbox"]');
    }

    initEvents() {
        if (this.DOM.moreBtn) {
            this.DOM.moreBtn.addEventListener('click', (e) => {
                e.preventDefault();
                this.getNextPage();
            });
        }


        this.DOM.checkboxes.forEach(checkbox => {
            checkbox.addEventListener('change', () => {
                const taxonomy = checkbox.getAttribute('data-taxonomy');
                const value = checkbox.value;
                this.lastFetch = 'filter';

                if (value === 'all') {
                    this.params[taxonomy] = [];
                    // Uncheck all other checkboxes in the same taxonomy group
                    this.DOM.checkboxes.forEach(cb => {
                        if (cb.getAttribute('data-taxonomy') === taxonomy && cb !== checkbox) {
                            cb.checked = false;
                        }
                    });
                } else {
                    if (checkbox.checked) {
                        // Uncheck the "all" checkbox if any specific checkbox is checked
                        this.DOM.checkboxes.forEach(cb => {
                            if (cb.getAttribute('data-taxonomy') === taxonomy && cb.value === 'all') {
                                cb.checked = false;
                            }
                        });
                        // Only allow one selection per taxonomy
                        this.params[taxonomy] = [value];
                        // Uncheck other checkboxes in the same taxonomy group
                        this.DOM.checkboxes.forEach(cb => {
                            if (cb !== checkbox && cb.checked && cb.getAttribute('data-taxonomy') === taxonomy) {
                                cb.checked = false;
                                const index = this.params[taxonomy].indexOf(cb.value);
                                if (index > -1) {
                                    this.params[taxonomy].splice(index, 1);
                                }
                            }
                        });
                    } else {
                        const index = this.params[taxonomy].indexOf(value);
                        if (index > -1) {
                            this.params[taxonomy].splice(index, 1);
                        }
                    }
                }

                this.params.page = 1;
                this.fetchCustomerCases();
            });
        });
    }

    updateUrlParams() {
        const urlParams = new URLSearchParams();
    
        if (this.params.sector.length) {
            urlParams.set('sector', this.params.sector.join(','));
        }
        if (this.params.job.length) {
            urlParams.set('job', this.params.job.join(','));
        }
    
        // Ne pas inclure "page" dans les paramètres de l'URL
        const newUrl = `${window.location.pathname.replace(/\/page\/\d+\//, '/')}${urlParams.toString() ? '?' + urlParams.toString() : ''}`;
        history.pushState({}, '', newUrl);
    }
    

    getNextPage() {
        this.params.page++;
        this.lastFetch = 'next';
        this.fetchCustomerCases();
    }

    fetchCustomerCases() {
        this.updateUrlParams();
        if (this.DOM.moreBtn) {
            this.DOM.moreBtn.classList.add('is-loading');  
        }

        this.request = request.AJAX({
            'url': window.JWP.ajax_url + "?action=" + this.action,
            'data': this.params,
            'success': this.onSuccess.bind(this)
        });
    }

    onSuccess(response) {
        const data = response.data;
        if (this.lastFetch === 'filter') {
            this.clearColumn();
        }

        if (data.customer_cases) {
            data.customer_cases.forEach(customer_case => {
                this.DOM.column.innerHTML += customer_case.html;
            });
            if (this.DOM.moreBtn) {
                this.DOM.moreBtn.classList.remove('is-loading');
            }
            setTimeout(() => {
                this.app.$cursor = new Cursor(this.app)
                this.app.$scroll.update();
            }, 200);
        }

        gsap.set(this.DOM.noPostMsg, { display: !data.customer_cases.length && this.lastFetch === "filter" ? 'block' : 'none' });
        gsap.set(this.DOM.moreBtn, { display: data.has_more ? 'flex' : 'none' });
    }

    clearColumn() {
        this.DOM.column.innerHTML = '';
    }

    destroy() {
        // Clean up event listeners or other resources
    }
}
